.header.bg-gradient-gray {
    background: linear-gradient(90deg, #ECECEE 0%, #ECECEE 3.53%, #ECECEE 9.34%, #ECECEE 14.06%, #ECECEE 19.62%, #ECECEE 25.44%, #ECECEE 30.67%, #ECECEE 35.64%, #ECECEE 41.55%, #ECECEE 47.09%, #ECECEE 52.2%, #EEEEEE 56.49%, #EBEBED 59.92%, #EAE9EB 63.86%, #E2E1E2 67.02%, #E0DEDF 70.82%, #DEDCDB 72.89%, #E0DEDF 75.21%, #E6E6E6 78.28%, #E7E6E7 80.98%, #E8E8E8 83.63%, #E9E7E7 86.07%, #E9E7E8 88.91%, #E9E7E8 91.05%, #E9E7E8 93.3%, #E9E7E8 94.88%, #E9E8E8 96.61%, #E9E8E8 98.35%, #E7E7E7 100%);
    @include r(768) {
        background: #eaeaeb;
        .mob_menu {
            background-color: #eaeaeb; } } }

.header {
    max-width: 100%;
    position: relative;
    z-index: 5;
    @include r(768) {
        &.header--mob-fixed {
            height: 50px;
            .mob_menu {
                position: fixed;
                width: 100%; } } } }
// Верхнее меню шапки
.header_top_menu {
    background-color: #444;
    color: #BCBCBC;
    font-size: 14px; }

.header_top_menu__row {
    margin-left: -10px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center; }

.header_top_menu__list {
    display: flex;
    align-items: center;
    height: 100%;
    .header_top_menu__item {
        height: 100%;
        align-items: center;
        display: flex;
        padding-bottom: 5px;

        padding-right: 10px;
        padding-left: 10px;
        a {
            text-decoration: none;
            color: inherit; }
        &:hover {
            color: #fff;
            background-color: #383838; } } }

.header_top_menu__city_wrapper {
    padding-bottom: 5px; }
.header_top_menu__city {
    color: #fff; }
button.header_top_menu__city {
	background: none;
	border: none;
	outline: none; }

.header_top_menu__address_wrapper {
    padding-bottom: 5px; }

// Меню в шапке
.header_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0; }

.header_logo {
    img {
        width: 190px;
        height: 40px; } }
.header_phone__wrapper {
    text-align: center;
    font-size: 12px;
    color: #868484;
    line-height: 1.2;
    text-decoration: none;

    &:hover {
        .header_phone {
            color: darken($red, 10%); } } }
.header_phone {
    text-decoration: none;
    color: #E32121;
    font-weight: 500;
    font-size: 20px; }


.header_menu {
    color: #444444;
    font-size: 16px;
    font-weight: 500;
    .header_menu__item {
        margin-right: 15px;
        padding: 15px 0;
        padding-right: 15px;
        &:hover {
            color: #E32121; }
        &:last-child {
            margin-right: 0; }
        a {
            color: inherit;
            text-decoration: none; }
        &--have-sublist {
            position: relative;
            &:before {
                display: block;
                content: '';
                width: 10px;
                height: 5px;
                position: absolute;
                right: 0px;
                top: 50%;
                transform: translateY(-50%);
                background-image: url(../img/header-arrow.svg);
                background-repeat: no-repeat; }
            &:hover {
                &:after {
                    position: absolute;
                    left: -7.5%;
                    bottom: 0;
                    width: 113%;
                    height: 3px;
                    background-color: $red;
                    display: block;
                    content: ''; }
                .header_menu__sublist {
                    display: flex;
                    flex-direction: column;
                    background-color: #fff;
                    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
                    position: absolute;
                    top: 100%;
                    left: -7.5%;
                    width: 250px;
                    padding: 18px 15px 10px 15px;
                    font-weight: 400;
                    color: $dark;
                    li {
                        margin-bottom: 15px; }
                    li:hover {
                        color: $red; } }

                &:before {
                    background-image: url(../img/header-arrow-active.svg); } }
            .header_menu__sublist {
                display: none; } } }
    .header_menu__list {
        display: flex;
        height: 100%; } }

@include r(768) {
    .header__menu_container, .header_top_menu {
        display: none; } }




