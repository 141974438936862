.employees {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &_info {
        &__text {
            line-height: 1.3; }
        &__stats {
            margin-top: 10px;
            display: flex;
            & &_title {
                font-size: 50px;
                font-weight: 500;
                color: $red;
                margin-bottom: 0;
                line-height: 1.1;
                &--plus {
                    display: inline-block;
                    position: relative;
                    &:before {
                        content: '+';
                        display: block;
                        font-size: 24px;
                        font-weight: 500;
                        position: absolute;
                        top: 0px;
                        right: -15px; } } }
            & &_description {
                color: #444;
                font-size: 20px;
                margin-bottom: 0; }
            &_item {
                &:nth-child(1) {
                    padding-right: 30px;
                    border-right: 1px solid #DDDDDD; }
                &:nth-child(2) {
                    padding-left: 30px; } } } }
    &_form {
        order: 1000;
        .form_box {
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 20px;
            min-width: 300px;
            &__title {
                font-size: 22px;
                &--regular {
                    line-height: 1.2;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    display: block;
                    white-space: normal; } } } } }


.employee {
    margin-top: 50px;
    width: 30%;
    padding: 0 15px;
    @include r(768) {
        width: 100%; }
    &__photo {
        width: 100%;
        &_wrapper {
            position: relative; } }


    &__name {
        font-weight: 500;
        font-size: 16px;
        line-height: 1.1;
        line-height: 20px;
        text-transform: uppercase; }
    &__job {
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase; }
    &__call_button {
        margin-left: 10px;
        position: relative;
        @include r(768) {
            img {
                width: 35px;
                height: 35px; } }
        &:hover {
            .employee__call_button-active {
                display: block;
                opacity: 1;
                z-index: 2; } }

        &-active {
            transition: .1s ease-in;
            display: none;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0; } }
    &__bottom {
        &_wrapper {
            margin-top: 15px;
            display: flex;
            justify-content: space-between; }
        &_column {
            display: flex;
            flex-direction: column; }
        &_row {
            display: flex; } } }

