.hero {
    height: 32vw;
    min-height: 450px;
    background-image: url(../img/header-bg-transparent.png);
    background-size: cover;
    background-position: bottom center;
    @include r(768) {
        border-radius: 0 0 5% 5%;
        background-color: #eaeaeb;
        height: 67vh;
        min-height: 90vw;
        background-image: url(../img/header-bg-girl-only.png);
        background-size: 50%;
        background-repeat: no-repeat;
        background-position-x: 120%;
        padding-bottom: 30px; } }
.hero_inner {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include r(768) {
        height: 100%; } }

.hero_text {
    font-weight: 500;
    font-size: 36px;
    line-height: 1.2;
    text-transform: uppercase;
    color: #444444;
    &--red {
        color: #E32121; }
    @include r(768) {
        font-size: 6vw;
        max-width: 90%;
        br {
            display: none; } } }
.hero_description {
    margin-top: 30px;
    font-size: 18px;
    line-height: 1.2;
    color: #222222;
    width: 530px;
    @include r(768) {
        font-size: 4vw;
        line-height: 1.3;
        max-width: 65%; } }
