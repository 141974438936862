.modal {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.25);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    display: none;
    transition: all .3s;
    background: rgba($dark, 0.9);

    &_inner {
        width: 100%;
        max-width: 525px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 2em;
        background: $white;
        padding: 40px 70px;
        text-align: center;
        .modal {
            &_success {
                display: none; }
            &_register {
                display: block; }
            &_title {
                font-weight: normal;
                font-size: 36px;
                line-height: 1.333;
                color: $dark; }
            &_subtitle {
                margin: 20px 0;
                color: $dark;
                font-size: 16px;
                line-height: 1.4; }
            &_close {
                position: absolute;
                top: 5px;
                right: 10px;
                margin: 0;
                padding: 0; }
            &_form {
                .form {
                    &_input {
                        background: #FFFFFF;
                        border: 1px solid rgba(51, 51, 51, 0.3);
                        box-sizing: border-box;
                        height: 50px;
                        width: 100%;
                        margin-bottom: 19px;
                        text-indent: 10px;
                        color: $dark;
                        font-size: 16px;
                        line-height: 1.4; }
                    &_button {
                        width: 100%;
                        height: 50px; } } } } } }
