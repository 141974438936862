.stats_grid {
    width: 440px;
    @include r(768) {
        width: 100%; }
    &__row {
        display: flex;
        &:first-child {
            border-bottom: 1px solid #DDDDDD; }
        &:nth-child(2) {
            .stats_grid__cell {
                padding-top: 25px;
                padding-bottom: 5px; } }
        .stats_grid__cell {
            &:first-child {
                padding-left: 0;
                border-right: 1px solid #DDDDDD; } } }
    &__cell {
        width: 50%;
        display: flex;
        padding: 15px 0 15px 20px; }
    &__heading {
        font-size: 70px;
        font-weight: 500;
        color: $red;
        line-height: 0.7;
        margin-right: 10px;
        @include r(768) {
            font-size: 13vw; } }
    &__text {
        color: #000;
        font-size: 20px;
        @include r(768) {
            font-size: 4.3vw; } } }


.our-stats {
    .green_badge--attach-bottom-left {
        bottom: 0;
        left: -45px;
        width: calc(100% + 55px);
        padding-top: 20px;
        padding-bottom: 25px;
        padding-left: 20px;
        @include r(768) {
            left: 0;
            width: 100%; } }

    .image_decoration--right {
        left: 40px;
        @include r(768) {
            left: auto; }
        .image_decoration__item {
            height: 90%;
            @include r(768) {
                height: auto; } } } }
