@font-face {
  font-display: swap;
  font-family: 'DIN Pro';
  src: url('../fonts/DINPro-Medium.woff') format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-display: swap;
  font-family: 'DIN Pro';
  src: url('../fonts/DINPro-BlackItalic.woff') format("woff");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-display: swap;
  font-family: 'DIN Pro';
  font-stretch: condensed;
  src: url('../fonts/DINPro-CondensedBlackItalic.woff') format("woff");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-display: swap;
  font-family: 'DIN Pro';
  font-stretch: condensed;
  src: url('../fonts/DINPro-CondensedMedium.woff') format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-display: swap;
  font-family: 'DIN Pro';
  src: url('../fonts/DINPro-MediumItalic.woff') format("woff");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-display: swap;
  font-family: 'DIN Pro';
  src: url('../fonts/DINPro-Bold.woff') format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-display: swap;
  font-family: 'DIN Pro';
  src: url('../fonts/DINPro-Light.woff') format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-display: swap;
  font-family: 'DIN Pro';
  font-stretch: condensed;
  src: url('../fonts/DINPro-CondensedMediumItalic.woff') format("woff");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-display: swap;
  font-family: 'DIN Pro';
  src: url('../fonts/DINPro-Italic.woff') format("woff");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-display: swap;
  font-family: 'DIN Pro';
  src: url('../fonts/DINPro.woff') format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-display: swap;
  font-family: 'DIN Pro';
  font-stretch: condensed;
  src: url('../fonts/DINPro-CondensedBlack.woff') format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-display: swap;
  font-family: 'DIN Pro';
  font-stretch: condensed;
  src: url('../fonts/DINPro-CondensedBold.woff') format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-display: swap;
  font-family: 'DIN Pro';
  src: url('../fonts/DINPro-Black.woff') format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-display: swap;
  font-family: 'DIN Pro';
  src: url('../fonts/DINPro-LightItalic.woff') format("woff");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-display: swap;
  font-family: 'DIN Pro';
  font-stretch: condensed;
  src: url('../fonts/DINPro-CondensedBoldItalic.woff') format("woff");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-display: swap;
  font-family: 'DIN Pro';
  font-stretch: condensed;
  src: url('../fonts/DINPro-CondensedRegular.woff') format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-display: swap;
  font-family: 'DIN Pro';
  font-stretch: condensed;
  src: url('../fonts/DINPro-CondensedItalic.woff') format("woff");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-display: swap;
  font-family: 'DIN Pro';
  font-stretch: condensed;
  src: url('../fonts/DINPro-CondensedLightItalic.woff') format("woff");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-display: swap;
  font-family: 'DIN Pro';
  font-stretch: condensed;
  src: url('../fonts/DINPro-CondensedLight.woff') format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-display: swap;
  font-family: 'DIN Pro';
  src: url('../fonts/DINPro-BoldItalic.woff') format("woff");
  font-weight: bold;
  font-style: italic; }

/* pt-sans-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/pt-sans-v11-latin-ext-regular.woff') format('woff'); }
