$sizes: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100, 150, 200, 225, 250, 275);
$sizesForPercent: (5, 10, 15, 20, 25, 30, 33, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 110, 115, 120, 150, 200, 225, 250, 275);

@each $size in $sizes {
    .w-#{$size} {
        width: #{$size}px; }
    .h-#{$size} {
        height: #{$size}px; } }

@each $size in $sizesForPercent {
    .w-#{$size}vw {
        width: #{$size}vw; }
    .h-#{$size}vh {
        height: #{$size}vh; }
    .w-#{$size}\% {
        width: #{$size}#{'%'}; }
    .h-#{$size}\% {
        height: #{$size}#{'%'}; } }

.w-33\.3\% {
    width: 33.3333%; }
.h-33\.3\% {
    height: 33.3333%; }

@include r(768) {
    @each $size in $sizes {
        .sm-w-#{$size} {
            width: #{$size}px; }
        .sm-h-#{$size} {
            height: #{$size}px; } }

    @each $size in $sizesForPercent {
        .sm-w-#{$size}vw {
            width: #{$size}vw; }
        .sm-h-#{$size}vh {
            height: #{$size}vh; }
        .sm-w-#{$size}\% {
            width: #{$size}#{'%'}; }
        .sm-h-#{$size}\% {
            height: #{$size}#{'%'}; } }
    .sm-w-33\.3\% {
        width: 33.3333%; }
    .sm-h-33\.3\% {
        height: 33.3333%; } }


.max-w-215 {
    max-width: 215px; }

.row {
    display: flex;
    flex-wrap: wrap; }
.row-space-between {
    justify-content: space-between; }
.row-center {
    justify-content: center; }
.flex-align-start {
    align-items: flex-start; }
.flex-align-center {
    align-items: center; }
.flex-align-baseline {
	align-items: baseline; }
.container-fluid {
    width: 100%;
    margin-right: auto;
    margin-left: auto; }
