.city_menu {
	display: none;
	&.active {
		display: flex; }
	&__content {
		flex-direction: column;
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 80%;
		max-width: 1200px;
		height: 70%;
		padding: 30px 2%;
		border-radius: 20px;
		box-shadow: 2px 5px 10px 0px rgba(0,0,0,0.3);
		background: #444444;
		color: #fff;
		z-index: 50;
		display: flex;
		@include r(767) {
			border-radius: 0;
			width: 100%;
			bottom: auto;
			height: 93%;
			padding: 20px 5px 15px 15px; } }

	&__overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		cursor: pointer;
		&:hover ~ .city_menu__content .city_menu__close {
			background-color: rgba(0,0,0,.1); } }
	&__close {
		background: none;
		border: none;
		outline: none;
		color: #fff;
		font-size: 50px;
		position: absolute;
		right: 20px;
		top: 15px;
		padding: .4rem 1.4rem;
		border-radius: 20px;
		user-select: none;
		&:hover, &:focus {
			background-color: rgba(0,0,0,.3); }
		&:active {
			background-color: rgba(0,0,0,.4); }
		@include r(767) {
			padding: .1rem 1.5rem; } }
	&__heading {
		font-weight: 700;
		font-size: 32px;
		padding-bottom: 40px;
		user-select: none;
		@include r(768) {
			padding-bottom: 30px;
			font-size: 28px; } }
	&__list {
		overscroll-behavior: contain;
		padding: 0 30px;
		overflow-y: scroll;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		font-size: 16px;
		line-height: 1.5;
		&::-webkit-scrollbar {
			width: 10px; }
		&::-webkit-scrollbar-track {
			border-radius: 10px;
			background: #eee;
			box-shadow: 0 0 1px 1px #bbb, inset 0 0 7px rgba(0,0,0,0.3); }
		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			box-shadow: inset 0 0 1px 1px #5C6670;
			background-color: #444;
			&:hover {
				background: #555; }
			&:active {
				background: #666; } }

		a {
			text-decoration: none;
			color: inherit;
			&:hover, &:focus {
				color: $green;
				text-decoration: underline; } }
		@include r(1024) {
			font-size: 12px;
			line-height: 1.8; }
		@include r(767) {
			padding: 0 20px 0 0;
			line-height: 2; } }

	&__column {
		display: flex;
		flex-direction: column;
		@include r(767) {
			width: 50%; } }
	&__row {
		position: relative;
		display: flex;
		margin-bottom: 25px;
		&_title {
			font-weight: 700;
			left: 0;
			top: 0;
			position: absolute;
			@include r(767) {
				display: flex;
				align-items: center;
				justify-content: center;
				background: #222;
				height: 1.5rem;
				width: 1.5rem;
				border-radius: 50%; } }
		&_content {
			padding-left: 25px;
			@include r(767) {
				padding-left: 2rem;
				padding-top: .3rem; } } } }


