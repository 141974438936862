.form_box {
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 33px 43px;
    min-width: 363px;
    @include r(768) {
        min-width: auto; }


    &--bg-transparent {
        background: transparent;
        box-shadow: none; }
    &__policy_description {
        font-size: 12px;
        color: #868484;
        a {
            color: inherit; } }

    &__title {
        white-space: nowrap;
        font-size: 24px;
        color: #444444;
        .red {
            color: $red;
            font-weight: 700;
            &.regular {
                font-weight: 400; } } }
    &__small_text {
        font-size: 14px;
        color: #444; }
    .form_box__phone {
        text-decoration: none;
        display: block;
        margin: 0 auto;
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 0;
        color: $red;
        text-align: center;
        position: relative;
        &_wrapper {
            display: flex; }
        &_icon {
            left: -28px;
            top: 50%;
            transform: translateY(-42%);
            position: absolute; } }
    .form_box &__phone_description {
        margin-bottom: 7px; } }
.form {
    &--color-white {
        .form_input {
            color: #fff;
            border-color: #fff;
            &:focus, &:active {
                border-color: #5aba7b; }
            &::-webkit-input-placeholder {
                color: #fff; }
            &:-ms-input-placeholder {
                color: #fff; }
            &::placeholder {
                color: #fff; } } }
    &--horizontal {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        @include r(768) {
            flex-direction: column;
            align-items: normal; }
        .form_group {
            @include r(768) {
                margin-right: 0;
                margin-bottom: 20px; }
            margin-right: 30px;
            margin-bottom: 0; }
        .form_input {
            width: 220px;
            font-size: 18px;
            margin-bottom: 0;
            padding-bottom: 10px; } }


    &_group {
        margin-bottom: 20px; }
    &_input {
        border: none;
        background: transparent;
        border-bottom: 2px solid rgba(162, 162, 162, 0.4);
        transition: .3s ease-in;
        color: #222;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
        font-size: 16px;
        font-family: inherit;
        padding-bottom: 5px;
        &:focus, &:active {
            border-color: #E32121; }
        &::-webkit-input-placeholder {
            color: #868484; }
        &:-ms-input-placeholder {
            color: #868484; }
        &::placeholder {
            color: #868484; }
 } }        //&[type=tel]

