//This .scss loop will create "margin helpers" and "padding helpers" for use in your web projects.
//It will generate several classes such as:
//.m-r-10 which gives margin-right 10 pixels.
//.m-r-15 gives MARGIN to the RIGHT 15 pixels.
//.m-t-15 gives MARGIN to the TOP 15 pixels and so on.
//.p-b-5 gives PADDING to the BOTTOM of 5 pixels
//.p-l-40 gives PADDING to the LEFT of 40 pixels
//The first letter is "m" or "p" for MARGIN or PADDING
//Second letter is "t", "b", "l", or "r" for TOP, BOTTOM, LEFT, or RIGHT
//Third letter is the number of spacing in pixels. Adjust the amounts generated by editing the $spaceamounts variable below.


$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100, -5, -10, -15, -20, -25, -30); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
    @each $side in $sides {
        .m#{str-slice($side, 0, 1)}-#{$space} {
            margin-#{$side}: #{$space}px !important; }
        @if $space >= 0 {
            .p#{str-slice($side, 0, 1)}-#{$space} {
                padding-#{$side}: #{$space}px !important; } } } }



@each $space in $spaceamounts {
    @each $side in $sides {
        @include r(768) {
            .sm-m#{str-slice($side, 0, 1)}-#{$space} {
                margin-#{$side}: #{$space}px !important; }
            @if $space >= 0 {
                .sm-p#{str-slice($side, 0, 1)}-#{$space} {
                    padding-#{$side}: #{$space}px !important; } } } } }
.px-5 {
    padding-left: 5px!important;
    padding-right: 5px!important; }
.px-10 {
    padding-left: 10px!important;
    padding-right: 10px!important; }
.px-15 {
    padding-left: 15px!important;
    padding-right: 15px!important; }
.px-20 {
    padding-left: 20px!important;
    padding-right: 20px!important; }
.py-0 {
    padding-top: 0!important;
    padding-bottom: 0!important; }
@include r(768) {
    .sm-px-0 {
        padding-left: 0!important;
        padding-right: 0!important; }
    .sm-px-5 {
        padding-left: 5px!important;
        padding-right: 5px!important; }
    .sm-px-10 {
        padding-left: 10px!important;
        padding-right: 10px!important; }
    .sm-px-15 {
        padding-left: 15px!important;
        padding-right: 15px!important; }
    .sm-px-20 {
        padding-left: 20px!important;
        padding-right: 20px!important; }
    .sm-py-0 {
        padding-top: 0!important;
        padding-bottom: 0!important; } }
