.gray-decoration {
    position: relative;
    &:before {
        z-index: -1;
        background-color: #f0f0f0;
        display: block;
        content: '';
        position: absolute;
        top: 0;
        right: -15%;
        width: 200%;
        height: 100%;
        border-radius: 0 1000px 1000px 0;
        @include r(768) {
            width: 100%;
            right: 0;
            border-radius: 0 50px 0 0; } } }
.red-decoration {
    position: relative;
    &:before {
        z-index: -1;
        background-color: #e32121;
        display: block;
        content: '';
        position: absolute;
        top: -20px;
        right: -20px;
        width: 400%;
        height: 100%;
        border-radius: 0 20px 150px 0;
        @include r(768) {
            right: auto;
            left: -10%;
            top: -20px;
            width: 120%;
            border-radius: 0; } } }

.image_decoration {
    position: relative;
    @include r(768) {
        &__sm {
            .image_decoration__item {
                display: block;
                position: relative;
                width: 100%;
                height: auto;
                left: 0;
                border-radius: 30px 30px 0 0;
                border: 1px dashed #ddd;
                border-width: 1px 1px 0 1px; } } }
    &--left {
        right: 0;
        .image_decoration__item {
            right: 0; } }
    &--right {
        left: 0;
        .image_decoration__item {
            left: 0; } }
    &.w-50\% {
        .image_decoration__item {
            min-width: 720px; } }

    &__item {
        height: 100%;
        width: 40vw;
        max-width: 600px;
        object-fit: cover;
        z-index: 2;
        top: 0;
        position: absolute;
        @include r(768) {
            display: none; } } }
