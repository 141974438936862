body {
    font-family: "DIN Pro", "Open Sans", "PT Sans", "Segoe UI", "Arial";
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    line-height: 1.2;
    color: $dark;
    background-color: #f9f9f9;
    @include r(768) {
        line-height: 1.3; } }
main {
    overflow: hidden; }

.container {
    margin: 0 auto;
    max-width: 95%;
    width: calc(31vh + 35vw);
    min-width: 960px;
    @include r(768) {
        padding-left: 5%;
        padding-right: 5%;
        min-width: auto;
        width: 100%;
        max-width: none; } }


.bg-gray {
    background-color: #ececee; }
.bg-red {
    background-color: $red; }
.bg-f0f0f0 {
    background-color: #f0f0f0; }
.color-white {
    color: #fff!important; }
.grayscale_filter {
    filter: grayscale(100%); }

.section {
    padding: 30px 0;
    max-width: 100%;
    overflow: hidden;
    .section_title {
        line-height: 1.2;
        text-transform: uppercase;
        color: #333;
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 40px;
        @include r(768) {
            font-size: 6.3vw;
            br {
                display: none; } }
        .red {
            color: $red; }
        &.mb-20 {
            margin-bottom: 20px; }
        &.mb-25 {
            margin-bottom: 25px; } }
    .section_subtitle {
        line-height: 1.2;
        margin-bottom: 20px;
        font-size: 24px;
        font-weight: bold;
        color: $red;
        @include r(768) {
            font-size: 6.3vw; } } }

.text {
    p {
        margin-bottom: 20px; } }
.regular {
    font-weight: 400; }
.medium {
    font-weight: 500; }
.bold {
    font-weight: 700; }
.text-center {
    text-align: center; }
.black {
    color: #000; }

.d-flex {
    display: flex; }
.d-block {
    display: block; }
.d-inline-block {
    display: inline-block; }
.d-none {
    display: none; }
@include r(768) {
    .sm-d-flex {
        display: flex; }
    .sm-d-block {
        display: block; }
    .sm-d-inline-block {
        display: inline-block; }
    .sm-d-none {
        display: none; }
    .sm-uppercase {
        text-transform: uppercase; } }


