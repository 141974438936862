.bg-reviews {
    position: relative;
    &:after {
        height: 80%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        content: '';
        z-index: -1;
        background-image: url(/img/review/bg-review.jpg);
        background-position: top center;
        background-size: cover; } }
