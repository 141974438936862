


.button_rounded {
    font-family: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 45px;
    border-radius: 40px;
    box-shadow: 0px 5px 10px rgba(227, 33, 33, 0.2);
    background-color: $red;
    transition: background-color .3s ease;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    padding-bottom: 0.2em;
    &.center {
        margin: 0 auto; }
    &:hover {
        background-color: darken($red, 10%);
        text-decoration: none; }
    &.button_rounded--arrow {
        span {
            position: relative;
            &:after {
                display: inline-block;
                margin-left: 7px;
                content: '';
                width: 6px;
                height: 11px;
                background-image: url(../img/icons/form-button-arrow.png);
                background-repeat: no-repeat; } } }
    &--gray {
        background-color: #444444;
        box-shadow: 0px 5px 10px rgba(33, 33, 33, 0.2);
        &:hover {
            background-color: $red; } }
    &--green {
        background-color: #5ABA7B;
        &:hover {
            background-color: darken(#5ABA7B, 15%); } }
    &--narrow {
        height: 40px;
        padding-bottom: 0.2em; } }
