.footer {
    color: #fff;
    &__logo {
        width: 53px;
        height: 30px;
        img {
            width: 100%; } }
    &__top_wrapper {
        padding-top: 35px;
        background-color: #444444; }
    &__bottom_wrapper {
        padding: 15px 0;
        background-color: #383838; }
    &__row_between {
        display: flex;
        justify-content: space-between;
        @include r(768) {
            flex-wrap: wrap; } }

    &__phone {
        margin-top: -5px;
        margin-bottom: 17px;
        display: block;
        color: #fff;
        text-decoration: none;
        font-size: 31px;
        font-weight: 500;
        &:hover {
            color: $red; } }
    &__copyright {
        font-size: 14px;
        font-weight: 300; }
    &__address {
        font-size: 16px;
        &_email {
            color: #fff;
            text-decoration: none;
            margin-bottom: 20px;
            display: block;
            &:hover {
                color: $red; } } }
    &__work_time {
        margin-top: 20px;
        display: block; }
    &__description {
        font-size: 16px;
        line-height: 20px;
        font-weight: 300;
        margin-top: 23px;
        margin-bottom: 20px; }
    &__s_icons {
        display: flex;
        padding-right: 5px; }
    &__s_icon {
        display: block;
        margin-left: 15px;
        position: relative;
        width: 30px;
        height: 30px;
        img {
            width: 100%;
            height: 100%; }
        &:hover {
            .footer__s_icon_active {
                z-index: 2;
                opacity: 1; } }
        &_active {
            transition: .1s ease-in;
            z-index: -1;
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0; } } }

