.slider__dots .glide__bullet--active {
    border-color: #5ABA7B;
    border-width: 2px; }
.slider {
    &__nav {
        margin-top: 15px;
        justify-content: center;
        display: flex;
        cursor: default; }
    &__dots {
        display: flex;
        align-items: center;
        margin: 0 20px; }
    &__dot {
        cursor: pointer;
        margin: 0px 2px;
        border-radius: 50%;
        border: 1px solid #C4C4C4;
        height: 10px;
        width: 10px;
        &--active {
            border-color: #5ABA7B;
            border-width: 2px; }
        &:hover {
            border-color: #5ABA7B;
            border-width: 2px; } }
    &__prev, &__next {
        cursor: pointer;
        height: 25px;
        width: 30px;
        position: relative;
        border: 1px solid #C4C4C4;
        transition: .05s ease-in;

        &:before {
            background-image: url("../img/icons/slider-arrow.svg");
            background-size: cover;
            display: block;
            position: absolute;
            content: '';
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 10px;
            width: 6px; }
        &:hover {
            transition: .05s ease-in;
            background-color: #5ABA7B;
            border-color: #5ABA7B;
            &:before {
                background-image: url("../img/icons/slider-arrow-active-white.svg"); } } }
    &__next {
        &:before {
            transform: translate(-50%, -50%) rotate(180deg); } }
    &--nav-inline {
        width: calc(100% - 120px);
        margin: 0 auto;
        position: relative;
        .slider {
            &__nav {
                margin-top: 0;
                position: absolute;
                justify-content: space-between;
                left: -60px;
                width: calc(100% + 120px);
                top: 50%;
                transform: translateY(-50%); }
            &__prev, &__next {
                border: none;
                &:hover {
                    border: none; }
                &:before {
                    background-image: url("../img/icons/slider-arrow.svg");
                    height: 18px;
                    width: 10px; }
                &:hover {
                    transition: .05s ease-in;
                    background-color: transparent;
                    border-color: transparent;
                    &:before {
                        background-image: url("../img/icons/slider-arrow-active.svg"); } } } } }


    &__img_slide {
        display: flex;
        align-items: flex-end;
        margin-bottom: 25px; }

    &__img {
        width: 65%;
        display: block;
        margin: 0 auto;
        img {
            width: 100%; } } }

