.mob_menu {
    display: none;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    padding: 10px;
    box-shadow: 0 0 20px rgba(116,126,138,0.4);
    @include r(768) {
        display: block;
        padding-left: 5%; }
    &__logo {
        position: relative;
        z-index: 10;
        line-height: 0;
        margin-left: 5px;
        img {
            height: 30px;
            width: 30px; } }
    &__city {
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 3.3vw; }
    &__phone {
        color: #333;
        font-size: 4vw;
        font-weight: 500;
        text-decoration: none;
        padding: 5px 4px;
        padding-left: 20px;
        border-radius: 3px;
        position: relative;
        svg, img {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 100;
            transform: translateY(30%); }
        .alt-text {
            position: absolute;
            align-items: center;
            flex-direction: row;
            z-index: 100;
            color: #999;
            font-size: 2.8vw;
            font-weight: 400;
            font-family: inherit;
            top: 0;
            left: 5.5vw;
            text-align: center;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: 0s; } }

    input {
        @include hidden;
        width: 30px;
        height: 30px;
        top: 10px;
        left: 50px;
        cursor: pointer;
        z-index: 2;
        -webkit-touch-callout: none;
        &:focus + .mob_menu__toogle_icon div {
            transform: scale(1.1); } }
    .mob_menu__toogle_icon {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 25px;
        height: 19px;
        cursor: pointer;
        transition: transform 0.3s ease-in-out;
        &:after {
            content: '';
            position: absolute;
            top: -10px;
            right: -10px;
            bottom: -10px;
            left: -10px; }
        &:hover {
            transform: scale(1.1);
            div {
                opacity: 1; } }
        &:active {
            transform: scale(1); }
        div {
            height: 3px;
            background-color: #000;
            opacity: 0.5;
            transition: transform 0.3s ease-in-out, opacity 0.55s ease-in-out; } }

    input:checked + .mob_menu__toogle_icon {
        div {
            background: #fff;
            opacity: 1;
            &:nth-of-type(1) {
                transform: translate3d(0, 8px, 0) rotate(45deg); }
            &:nth-of-type(2) {
                transform: scale(0, 1); }
            &:nth-of-type(3) {
                transform: translate3d(0, -8px, 0) rotate(-45deg); } } }
    input:checked ~ .mob_menu__phone {
        svg path {
            fill: #23B35C;
            transition: .3s ease-in; }
        .alt-text {
            display: flex;
            opacity: 1;
            transition: .3s ease-in .2s; } }


    input:checked ~ .mob_menu__content {
        transform: none; }
    input:checked ~ .mob_menu__content {
        opacity: 1; }
    input:checked ~ .mob_menu__content li {
        transform: translate(0, 0); } }
.mob_menu__content {
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow: scroll;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 50px 0 0 0;
    background: #444444;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    opacity: 0;
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: all 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);



    .mob_menu__links {
        padding: 0 20px 30px 20px;
        height: 100%;
        overflow: scroll;
        & > li > a {
            text-transform: uppercase;
            font-size: 7vw;
            padding-bottom: 30px; }

        li {
            padding: 1em 0;
            line-height: 1.2;
            text-transform: lowercase;
            transition: all 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
            transform: translate(-50%, 0);
            a {
                margin: 0;
                color: #fff;
                text-decoration: none;
                transition: color 0.3s ease;
                &:hover {
                    color: darken(#ccc, 15%); } } } }

    .mob_menu__link--have-sublist {
        ul {
            margin: 10px 0;
            li {
                padding: .55em 0 .55em .15em;
                a {
                    color: #ddd;
                    font-size: 3.8vw; } } } } }
