.green_badge {
    background-color: #5ABA7B;
    color: #fff;
    font-style: italic;
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px;
    padding: 15px 20px;
    margin: 0 -15px;
    margin-bottom: 20px;
    @include r(768) {
        margin-left: 0;
        margin-right: 0; }
    &__title {
        font-size: 20px;
        font-weight: 700;
        font-style: normal;
        display: block; }
    &__text {
        display: block;
        font-weight: 400;
        font-style: normal; }
    &--left-wide {
        padding-left: 10%;
        width: 110%;
        transform: translateX(-7.5%);
        @include r(768) {
            width: 100%;
            transform: none;
            padding-left: 20px; } }
    &--attach-bottom-left {
        padding-left: 30px;
        padding-right: 30px;
        position: absolute;
        bottom: 10px;
        left: -15px;
        z-index: 2;
        @include r(768) {
            position: relative;
            left: auto;
            right: auto;
            bottom: auto; }
        &.w-100\% {
            width: calc(100% + 30px);
            @include r(768) {
                width: 100%; } } } }
