.list_arrowed {
    margin-bottom: 15px;
    li {
        padding-left: 20px;
        margin-bottom: 4px;
        position: relative;
        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 5px;
            width: 12px;
            height: 10px;

            background-image: url(../img/icons/list-arrow.svg); } } }
