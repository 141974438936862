

.accreditations {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
    padding: 5px 20px 0 40px;
    @include r(768) {
        padding: 0; }
    &-slider {
        @include r(768) {
            width: 70%;
            position: relative;
            margin-left: -10px; } } }

.accreditation {
    display: block;
    cursor: pointer;
    flex-grow: 1;
    padding: 15px 10px;
    text-decoration: none;

    &__image_wrapper {
        background-color: #e6e6e6;
        padding: 3px 5px;
        width: 100%;
        position: relative;
        cursor: pointer;
        &:hover {
            .accreditation__image {
                transform-origin: 50% 50%;
                transform: scale(1.02); }
            &:after {
                z-index: 1;
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                display: block;
                height: 100%;
                width: 100%;
                background-color: rgba(0, 0, 0, 0.1); }

            &:before {
                z-index: 1;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: block;
                width: 50px;
                height: 50px;
                opacity: 0.2;
                content: '';
                background-image: url(../img/icons/zoom-in.png);
                background-size: cover; } } }

    &__image {
        transition: .1s ease-in;
        width: 100%; }
    &__text_wrapper {
        margin-top: 15px; }
    &__text {
        text-align: center;
        font-weight: 500;
        color: #444;
        font-size: 14px; } }

@include r(768) {
	.accreditation {
		&__image_wrapper {
			&:hover {
				.accreditation__image {
					transform: none; }
				&:after {
					display: none; }
				&:before {
					display: none; } } } } }
