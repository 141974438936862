.review_slider {
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px); }

.glide__slide {
    height: auto!important;
    padding: 20px 0; }

.review_panel {
    height: 100%;
    margin: 10px;
    max-width: 300px;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #F9F9F9;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 20px 25px;
    padding-top: 60px;
    line-height: 1.3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    transition: .15s ease-in;
    cursor: pointer;
    &:hover {
        transition: .15s ease-in;
        transform: translateY(-7px);
        .review_panel__button_more:before {
            transition: .15s ease-in;
            opacity: 1; } }
    &:before {
        position: absolute;
        top: 23px;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        content: '';
        background-image: url(../img/review/quote.svg);
        width: 19px;
        height: 14px;
        margin: 0 auto; }
    &__text {
        font-size: 16px;
        margin-bottom: 20px;
        color: #222; }
    &__bottom_wrapper {
        padding-top: 10px;
        border-top: 1px solid #DDDDDD;
        display: flex;
        align-items: flex-end;
        justify-content: space-between; }
    &__company_logo {
        line-height: 1;
        margin-right: 15px; }
    &__company_info {
        p {
            line-height: 1.15;
            font-size: 14px;
            color: #868484;
            margin-bottom: 0; } }
    &__button_more {
        background-color: transparent;
        background-image: url(../img/review/details.svg);
        height: 30px;
        width: 30px;
        position: relative;

        &:before {
            transition: .15s ease-in;
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;
            display: block;
            content: '';
            background-image: url(../img/review/details-active.svg);
            width: 100%;
            height: 100%; } } }

@include r(768) {
    .review_panel {
        max-width: none; } }
